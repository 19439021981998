
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import SEO from '../../../shared/components/seo';
import Pagination from '../../molecules/pagination/pagination';
import './home-content.scss';
import SectionHomeSustainibility from '../../molecules/section-home-sustainibility/section-home-sustainibility';
import SectionHomeMembers from '../../molecules/section-home-members/section-home-members';
import SectionCardArticles from '../../molecules/section-card-articles/section-card-articles';
import Img from "gatsby-image"
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import SectionHomeProducts from '../../molecules/section-home-products/section-home-products';

const HomeContent = props => {
  const resultQuery = useStaticQuery(
    graphql`
      query HOME_QUERY {
        allContentfulHome {
          nodes {
            node_locale
            sostenibilityLink
            carousel {
              id
              slug
              title
              internalRedirect
              
              description {
                description
              }
              imagen {
                fluid(maxWidth: 1920, quality: 100) {
									...GatsbyContentfulFluid_withWebp
                }
                file {
                  url
                }
              }
            }
            productsModuleImage {
              file {
                url
              }
            }
            productsModuleDescription {
              productsModuleDescription
            }
            productsModuleCategory {
              title {
                title
              }
              slug
            }
            environmentalTitle
            environmentalDescription {
              environmentalDescription
            }
            environmentalImage {
              file {
                url
              }
            }
            featuredProducts {
              slug
              title
              isNew
              description {
                description
              }
              imagen {
                fluid(maxWidth: 400) {
									...GatsbyContentfulFluid_withWebp
                }
                file {
                  url
                }
              }
            }
            title
            metaTitle
            metaDescription {
              metaDescription
            }
          }
        }
        allContentfulArticulos(sort: {order: DESC, fields: fechaDePublicacionDelArticulo}) {
          nodes {
            node_locale
            contentful_id
            tipoDelArticulo
            tituloDelArticulo
            slug
            fechaDePublicacionDelArticulo(formatString: "YYYY/MM/DD")
            categoriaDelArticulo {
              description {
                description
              }
            }
            descripcinCortaDelArticulo {
              descripcinCortaDelArticulo
            }
            previsualizacionDelArtculo {
              description
              file {
                url
              }
            }
          }
        }

        allContentfulHome {
          nodes {
            node_locale
            articulosDestacados {
              contentful_id
              tipoDelArticulo
              tituloDelArticulo
              slug
              fechaDePublicacionDelArticulo(formatString: "YYYY/MM/DD")
              categoriaDelArticulo {
                description {
                  description
                }
              }
              descripcinCortaDelArticulo {
                descripcinCortaDelArticulo
              }
              previsualizacionDelArtculo {
                description
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  );
    
  const contentInfo = resultQuery.allContentfulHome.nodes.filter(e => e.node_locale === process.env.LOCALE)[0];
        const {
    title,
    metaTitle,
    metaDescription,
    carousel,
    featuredProducts,
  } = resultQuery.allContentfulHome.nodes.filter(e => e.node_locale === process.env.LOCALE)[0];;
  const [indexCarousel, setIndexCarousel] = React.useState(0);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const { isMobile, setmodalActive } = props;

  const articlesProm = resultQuery.allContentfulHome.nodes.filter(e => e.node_locale === process.env.LOCALE)[0].articulosDestacados.slice(0, 3)
  const articlesPublished = resultQuery.allContentfulArticulos.nodes.filter(e => e.node_locale === process.env.LOCALE).slice(0, 3)

  const articles = articlesProm.concat(articlesPublished).slice(0, 3)

  const datalayerBannerPrincipal = (labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Banners',
      category: 'banner principal',
      action: 'click',
      label: labelText,
      location: location,
      banner_name: labelText,
      user_data: [],
      log_status: '',
    })
  }

  const renderItemCarousel = (sliders, isMobile) => {
    return sliders.map((val, index) => {
      if (val.internalRedirect) {
        return (
          <div key={index} className="f-item-carousel">
            <a className="nostyle" href={val.slug} onClick={() => datalayerBannerPrincipal(val.title)}>
              {val.imagen[isMobile ? 1 : 0]?.fluid ?
                <Img
                  className="f-item-carousel-img"
                  fluid={val.imagen[isMobile ? 1 : 0].fluid}
                  alt={"description"}
                  loading='eager'
                />
                :
                <img
                  className="f-item-carousel-img"
                  alt="imagen carousel home"
                  src={isMobile ? val.imagen[1].file.url : val.imagen[0].file.url}
                  loading='eager'
                ></img>
              }
            </a>
          </div>
        )
      } else {
        return (
          <div key={index} className="f-item-carousel">
            <a className="nostyle" href={val.slug} onClick={() => datalayerBannerPrincipal(val.title)} target="_blank" rel="noopener noreferrer">
              {val.imagen[isMobile ? 1 : 0]?.fluid ?
                <Img
                  className="f-item-carousel-img"
                  fluid={val.imagen[isMobile ? 1 : 0].fluid}
                  alt={"description"}
                  loading='eager'
                />
                :
                <img
                  className="f-item-carousel-img"
                  alt="imagen carousel home"
                  src={isMobile ? val.imagen[1].file.url : val.imagen[0].file.url}
                  loading='eager'
                ></img>
              }
            </a>
          </div>
        )
      }
    });
  };

  const datalayerCardHome = (CategoryText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Home',
      category: CategoryText,
      action: 'click',
      label: 'Conoce más',
      location: location,
      user_data: [],
      log_status: '',
    })
  }

  const renderFeaturedProduct = featuredProducts => {
    return featuredProducts.map((val, index) => {
      return (
        <div key={index} className={` f-card-slider-horizontal`}>
          <a href={val.slug} className={"nostyle"} onClick={() => datalayerCardHome(val.title)}>
            {val.imagen[0]?.fluid ?
              <Img
                className="f-card-slider-horizontal__img"
                fluid={val.imagen[0].fluid}
                alt={"description"}
              /> :
              <img
                alt="imagen featured product"
                src={val.imagen[0].file.url}
              />
            }
          </a>
          {val.isNew ? (
            <div className={`f-card-tag-slider-horizontal ${val.isNew ? 'f-card-tag-new' : ''}`}>
              {val.characteristic}
            </div>
          ) : null}
        </div>
      )
    });
  };

  const handleChangeIndex = index => {
    setIndexCarousel(index);
  };

  return (
    <div className="f-home-container">
      <div className="f-home-content">
        <SEO
          title={metaTitle ? metaTitle : title}
          description={
            metaDescription !== null ? metaDescription.metaDescription : ''
          }
          lang="ES"
        />
        <section className="f-carousel-home">
          <AutoPlaySwipeableViews
            index={indexCarousel}
            onChangeIndex={handleChangeIndex}
            interval={6000}
            autoplay={true}
          >
            {renderItemCarousel(carousel, isMobile)}
          </AutoPlaySwipeableViews>
          <Pagination
            dots={carousel.length}
            index={indexCarousel}
            onChangeIndex={handleChangeIndex}
          />
          <div className="f-carousel-arrow">
            <i
              className="fas fa-lg fa-chevron-up"
              onClick={() =>
                document
                  .getElementById('home-sustainibility')
                  .scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            ></i>
          </div>
        </section>

        <SectionHomeProducts infoContent={contentInfo} />

        {(process.env.LOCALE === 'es-PR' ) && (
          <SectionHomeMembers infoContent={contentInfo} isMobile={isMobile} />
        )}

        {(process.env.LOCALE === 'es-CO' || process.env.LOCALE === 'es-EC' ||
              process.env.LOCALE === 'es-DO') && (
                <SectionHomeSustainibility
            infoContent={contentInfo}
            isMobile={isMobile}
          />
            )}

        <span className="f-separator-h" />

        <section className="f-slider-horizontal">
          {renderFeaturedProduct(featuredProducts)}
        </section>
      </div>
    </div>
  );
};

export default HomeContent;